<template>
  <div>
    <div class="add_box form1">
      <el-form
        ref="postForm"
        :model="postForm"
        :rules="rules"
        class="demo-ruleForm"
        label-width="150px"
        :inline="true"
        size="mini"
      >
        <h2>Create Main User</h2>
        <el-form-item label="User Name" prop="nickName">
          <el-input
            v-model.trim="postForm.nickName"
            placeholder="Please Enter the User Name"
            maxlength="50"
            :disabled="ifadd"
          />
        </el-form-item>
        <el-form-item label="Password" prop="pwd">
          <el-input
            v-model.trim="postForm.pwd"
            placeholder="Please Enter the password"
            maxlength="50"
            :disabled="ifadd"
          />
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input
            v-model.trim="postForm.email"
            placeholder="Please Enter the Email"
            maxlength="50"
            :disabled="ifadd"
          />
        </el-form-item>
        <el-form-item label="Phone" prop="phoneNumber">
          <el-input
            v-model.trim="postForm.phoneNumber"
            placeholder="Please Enter the phoneNumber"
            oninput="value=value.replace(/[^0-9.]/g,'')" 
            maxlength="20"
            :disabled="ifadd"
          />
        </el-form-item>
        <el-form-item label="Enterprise Name:" size="mini">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No" prop="contractNo">
          <template>
            <el-input
              v-model.trim="postForm.contractNo"
              placeholder="Please Enter the Contract No"
              maxlength="25"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showContractNo"
              />
            </el-input>
          </template>
        </el-form-item>
        <!-- <el-form-item label="User type" prop="userType">
          <el-select
            v-model.trim="postForm.userType"
            placeholder="Please select"
          >
            <el-option
              v-for="item in UserTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <div style="width: 100%; display: flex; justify-content: center">
          <el-button
            v-show="dialogStatus == 'create'"
            type="primary"
            size="mini"
            @click="createMainUser('postForm')"
          >
            Submit
          </el-button>
          <el-button
            v-show="dialogStatus == 'update'"
            
            type="primary"
            size="mini"
            @click="updateMainUser('postForm')"
          >
            Submit
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="add_box form2">
      <el-form
        ref="batchForm"
        :model="batchForm"
        :rules="ruleBatch"
        class="demo-ruleForm"
        label-width="150px"
        :inline="true"
        size="mini"
      >
        <div class="btn_box">
          <h2>Batch Create Child User</h2>
          <el-button
            
            size="mini"
            style="height: 40px !important"
            @click="downLoadTemplat('postForm')"
          >
            Excel Templete Download
          </el-button>
        </div>

        <el-form-item label="Upload" prop="fileList">
          <el-upload
            ref="upload"
            accept=".xlsx, .xls"
            class="upload-demo"
            :action="thisApi + '/system/user/saveBatchChildUser'"
            :data="{ contractNo: contractNo_ }"
            :file-list="batchForm.fileList"
            :auto-upload="false"
            :on-change="onChangeFile"
            :on-success="successChange"
            :on-error="onErorr"
          >
            <el-button
              slot="trigger"
              size="small"
              icon="sl-icon-download"
              style="float: left"
            >
              Upload
            </el-button>
            <div
              slot="tip"
              class="el-upload__tip"
              style="float: right; margin: 0 5px !important; line-height: 30px"
            >
              Support  xlsx, xls
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="Mount Contract No"
          prop="mountContractNo"
          label-width="165px"
        >
          <el-input
            v-model.trim="batchForm.mountContractNo"
            type
            placeholder="Please Enter the Mount Contract No"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            v-show="dialogStatusB == 'create'"
            type="primary"
            size="mini"
            :loading="isLoading"
            @click="createChildUser('batchForm')"
          >
            Submit
          </el-button>
          <el-button
            v-show="dialogStatusB == 'update'"
            
            type="primary"
            size="mini"
            @click="updateChildUser('batchForm')"
          >
            Submit
          </el-button>
        </el-form-item>
      </el-form>
      <hr style="margin: 30px">
      <el-form class="demo-ruleForm" size="mini" label-width="175px" :inline="true">
        <el-form-item label="Sales ERP" prop="salesERP" size="mini">
          <el-input
            v-model.trim="salesERP"
            type="text"
            placeholder="Please Enter the Sales ERP"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item label="Contract No" prop="contractNo" size="mini">
          <el-input
            v-model.trim="contractNo"
            type="text"
            placeholder="Please Enter the contractNo"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item size="mini">
          <el-button type="primary" @click="SearchList">Search</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="add_box list">
      <div class="panel-body demoWrapper">
        <el-table
          :data="fileList"
          fit
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column type="index" width="120" label="Sequence" />
          <el-table-column
            label="Contract No"
            min-width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.contractNo || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Operator"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.operator || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Main User"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.mainUser || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Submit Time"
            min-width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.created | filterDate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Excute Time"
            min-width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.modified | filterDate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Processing State"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div v-for="(item, index) in zhi" :key="index">
                <span v-show="item.value == scope.row.taskState">
                  {{ item.label }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="Result Download"
            width="140"
            :show-overflow-tooltip="true"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button :disabled="!scope.row.resultFileUrl" @click="downLoadBtn(scope.row)">
                Download
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="listQuery.pageCode"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="listQuery.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listQuery.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import dateFormate from '../../utils/date'
import Delotal from '../contractManage/conttemplat.vue';
import { getEnterpriseName } from '@/api/enterprise/enterprise';
import {
  addUser,
  getParticulars,
  userTaskPage,
  downLoad
} from '@/api/user/list';

const defaultForm = {
  nickName: '',
  pwd: '',
  email: '',
  phoneNumber: '',
  contractNo: '',
};
const batchForm = {
  fileList: [],
  nickName: '',
  email: '',
  Cellphonenumber: '',
  Position: '',
  pwd: '',
  // userType: ''
};
export default {
  // 引入局部刷新
  name: 'Add',
  inject: ['reload'],
  components: {
    Delotal
  },
  filters:{
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      // 获取URL信息
      outeObject: this.$route.query,
      // 单个创建时初始数据
      postForm: Object.assign({}, defaultForm),
      batchForm: Object.assign({}, batchForm),
      dialogStatus: 'update',
      dialogStatusB: 'update',
      contractNo:'',
      salesERP:'',
      contractNoif: true,
      isLoading:false,
      // Btn_: true,
      rules: {
        nickName: [
          {
            required: true,
            message: 'Please Enter the User Name',
            trigger: 'blur'
          }
        ],
        pwd: [
          {
            required: true,
            message: 'Please Enter New Password',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: 'Between 6 to 16 characters',
            trigger: 'blur'
          },
          {
            pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
            message: 'must contain number&letter',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: 'Please Enter the email',
            trigger: 'blur'
          },
          {
            validator: function(rule, value, callback) {
              if (
                /^[A-Za-z0-9-._]+@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,6})$/i.test(value) ==
                false
              ) {
                callback(new Error('EnterpriseEmail format error'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: 'Please Enter the phone',
            trigger: 'blur'
          }
        ],
        contractNo: [
          {
            required: true,
            message: 'Please Enter the contractNo',
            trigger: 'blur'
          }
        ]
      },
      // 弹窗中表格分页
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      downloadURL: '/system/user/downloadTarget',
      total: 0,
      fileList: [],
      // 批量创建user校验呢容
      ruleBatch: {
        fileList: [
          {
            required: true,
            message: 'Please upload .xlsx or .xls.',
            trigger: 'change'
          }
        ],
        mountContractNo: [
          {
            required: true,
            message: 'Please Enter the Mount Contract No',
            trigger: 'blur'
          }
        ],
      },
      // UserFlagList: [{ value: 1, label: 'VSP' }],
      UserTypeList: [
        { value: 1, label: 'Main User' },
        // { value: 2, label: 'Child User' }
      ],
      // 0：未执行；1：执行中；2：执行失败；3：执行成功
      zhi: [
        { value: '0', label: 'On process.' },
        { value: '1', label: 'The execution of' },
        { value: '2', label: 'On failure' },
        { value: '3', label: 'Execute successfully' }
      ],
      textMap: {
        update: 'update',
        create: 'create'
      },
      contractNo_: '',
      thisApi: process.env.VUE_APP_BASE_API,
      ifadd: false,
      dialogSKU: false,
      multipleSelections: '',
      phone: null,
      EnterpriseList: [],
      enterpriseNameCode: '',
    };
  },
  watch: {
    'batchForm.mountContractNo': function(val) {
      this.contractNo_ = val;
      console.log(val);
      if (val != '') {
        this.contractNoif = false;
      } else {
        this.contractNoif = true;
      }
    },
    '$route' :function (val){
      this.reload()
    }
  },
  created() {
    if (this.outeObject.icon == undefined) {
      this.dialogStatus = 'create';
      this.dialogStatusB = 'create';
    } else {
      this.dialogStatus = 'update';
      this.dialogStatusB = 'update';
      getParticulars(this.outeObject.icon).then(res => {
        this.ifadd = true;
        this.postForm = res.data;
      });
    }
    this.getEnterpriseNameData();
    this.getFirstData();
    this.$nextTick(()=>{
      this.$refs.batchForm.validateField('fileList');
    })
   
    this.emptyImgSrc = '';
  },

  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.postForm.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showContractNo() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateDatas() {
      this.postForm.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    SearchList(){
      this.getFirstData()
    },
    // 获取日志列表
    getFirstData() {
      userTaskPage(
        {
          pageNo: this.listQuery.pageCode,
          pageSize: this.listQuery.pageSize,
          contractNo: this.contractNo,
          operator:this.salesERP,
        }
      ).then(res => {
        if (res.success && res.code === 200) {
          this.fileList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          this.fileList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    downLoadTemplat() {
      window.location.href =
        this.thisApi + '/system/user/downloadTarget?fileName=userTemplat.xls';
    },
    // 新增主账号
    createMainUser() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          this.postForm.eid = localStorage.getItem('eid');
          let postForms = JSON.parse(JSON.stringify(this.postForm));
          this.addUser(postForms);
        } else {
          return false;
        }
      });
    },
    addUser(list) {
      addUser(list)
        .then(res => {
          if (res.code == 200) {
            this.$message({
              message: 'Creating a User succeeded',
              type: 'success'
            });
            setTimeout(() => {
              this.postForm = Object.assign({}, defaultForm);
              this.Btn_ = false;
            }, 1000);
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
            
          }
        })
        .catch(() => {
          this.$message({
            message: 'Failed to create a user',
            type: 'error'
          });
          // setTimeout(() => {
          //   this.postForm = Object.assign({}, defaultForm);
          // }, 1000);
        });
    },

    // 修改主账号
    updateMainUser() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增子账号
    createChildUser() {
      this.$refs['batchForm'].validate(valid => {
        this.isLoading=true
        if (valid) {
          this.$refs.upload.submit();
          setTimeout(()=>{
            this.isLoading=false
          })
          // this.getFirstData();
          // setTimeout(() => {
          //   this.$refs.upload.clearFiles();
          //   this.batchForm = Object.assign({}, batchForm);
          //   this.Btn_ = true;
          //   this.$message({
          //     message: 'successfully upload',
          //     type: 'success'
          //   });
          // }, 1000);
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 批量创建-修改确定
    updateChildUser() {
      this.$refs['batchForm'].validate(valid => {
        if (valid) {
          this.getFirstData();
          setTimeout(() => {
            this.$refs.upload.clearFiles();
            this.batchForm = Object.assign({}, batchForm);
            this.Btn_ = true;
          }, 1000);
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    onChangeFile(file, fileList) {
      if (file.name.split('.').pop() != 'xlsx' && file.name.split('.').pop() != 'xls') {
        fileList = []
        this.fileList = []
        this.$message({
          message: '请上传.xlsx或xls格式的文件',
          type: 'error'
        })
      } else {
        this.batchForm.fileList = fileList.length > 0 ? [file] : []
        this.$refs.batchForm.validateField('fileList');
      }
    },
    // 上传成功
    successChange(file, fileList) {
      this.isLoading=false
      if (file.code == 200) {
        this.getFirstData();
      } else {
        this.$message({
          message: file.msg,
          type: 'error'
        });
      }
    },
    onErorr(e){
      // 上传失败
      this.isLoading=false
      this.$message({
        message: e.msg,
        type: 'error'
      });
    },
    downLoadBtn(row) {

      window.location.href =
        this.thisApi +
        '/system/user/downloadTarget?fileName=' +
        row.resultFileUrl;
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  padding: 20px 10px;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 20px;
  h2 {
    height: 20px;
    font-size: 20px;
    font-family: Arial;
    font-weight: normal;
    color: rgba(36, 37, 38, 1);
  }
}
.add_box:nth-last-child(1) {
  margin-bottom: 0;
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.demoWrapper {
  margin-bottom: 5vh;
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 0px 10px 0;
    float: right !important;
    height: 45px;
  }
}
.btn_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.form1 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  //width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
