<template>
  <el-menu class="navbar" mode="horizontal">
    <p
      type="text"
      :class="leftif ? 'left_btn' : 'left_btnT'"
      @click="toggleSideBar"
    >
      <i :class="leftif ? 'el-icon-caret-left' : 'el-icon-caret-right'" />
    </p>
    <div class="title" style="margin: 7px 24px">Options Operation And Management System</div>
    <div class="right">
      <el-button class="user-button">
        <span class="user-name">{{ userName }}</span>
        <i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <div class="quit" @click="logout">
        <img
          src="@/assets/quit.png"
          class="quit-img"
          width="24px"
          height="24px"
        >
        <span class="quit-text">Quit</span>
      </div>
    </div>
  </el-menu>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import { mapMutations } from 'vuex';
import { loginOut, getPin } from '../../api/navBar/api';

export default {
  components: {},
  data: function() {
    return {
      isShow: true,
      leftif: true,
      imgsrc: '',
      userName: ''
    };
  },
  computed: {
    // ...mapGetters([
    //   'sidebar',
    //   'avatar'
    // ])
  },
  created() {
    this.getPin();
  },
  methods: {
    ...mapMutations({
      setCollapseState: 'setCollapseState'
    }),
    toggleSideBar() {
      this.setCollapseState();
      this.leftif = !this.leftif;
    },
    logout() {
      loginOut({ domain: '' }).then(res => {});
    },
    getPin() {
      getPin().then(res => {
        if (res.success && res.code === 200) {
          this.userName = res.data;
        } else {
          this.userName = 'User Name';
        }
      });
    }
  }
};
</script>

<style lang="less" type="text/less" rel="stylesheet/less" scoped>
.navbar {
  position: relative;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  align-content: space-between;
  .title {
    font-size: 18px;
    font-family: ArialMT;
    font-weight: Bold;
    color: rgba(0, 0, 0, 0.85);
  }
  .right {
    display: flex;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 9px;
    .user-button {
      min-width: 174px;
      height: 32px;
      margin-right: 24px;
      padding: 0 12px;
      font-size: 14px;
      font-family: Arial;
      font-weight: Bold;
      color: rgba(36, 37, 38, 1);
      background: white;
      // position: relative;
      // top: -10px;

      .el-icon-arrow-down {
        float: right;
      }
    }

    .quit {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      .quit-text {
        line-height: 32px;
        font-family: Arial;
        font-weight: Bold;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 8px;
      }
    }
  }
}
.left_btn {
  position: fixed;
  top: 45%;
  left: 240px;
  z-index: 9999;
  background: #fff;
  height: 100px !important;
  width: 20px;
  border-radius: 0 20px 20px 0;
  box-shadow: 4px 0 4px 0 rgba(183, 188, 201, 0.45);
  line-height: 100px;
  text-align: center;
}
.left_btnT {
  position: fixed;
  top: 45%;
  left: 64px;
  width: 20px;
  height: 100px !important;
  z-index: 999;
  background: #fff;
  border-radius: 0 20px 20px 0;
  box-shadow: 4px 0 4px 0 rgba(183, 188, 201, 0.45);
  line-height: 100px;
  text-align: center;
}

.dropdown-quit {
  width: 174px;
  margin-right: 24px;
}
</style>
