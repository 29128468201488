<template>
  <div class="orderList">
    <div class="modeify">
      <el-form
        :inline="true"
        label-width="100px"
        class="user-search fixed_form"
      >
        <el-form-item label="Account" size="mini" class="lableHeight">
          <template>
            <el-input
              v-model.trim="form.userPin"
              placeholder="Please Enter"
              size="mini"
              maxlength="120"
              clearable
            />
          </template>
        </el-form-item>
        <!-- 搜索按钮 -->
        <div style="float: right">
          <el-button size="mini" style="margin-right: 20px" @click="offReset">
            Reset
          </el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            @click="searchBtn"
          >
            Search
          </el-button>
        </div>
        <!-- Account -->
        <el-form-item label="Order No" size="mini" class="lableHeight">
          <template>
            <el-input
              v-model.trim.number="form.orderId"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder="Please Enter"
              size="mini"
              clearable
            />
          </template>
        </el-form-item>
        <el-form-item label="Real Order" size="mini" class="lableHeight">
          <template>
            <el-input
              v-model.trim.number="form.realOrderId"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder="Please Enter"
              size="mini"
              maxlength="12"
              clearable
            />
          </template>
        </el-form-item>
        <!-- Book Time -->
        <el-form-item label="Book Time" size="mini">
          <template>
            <el-date-picker
              v-model.trim="bookTime"
              size="mini"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 300px"
              ormat="yyyy-MM-dd HH:mm-:ss"
              type="datetimerange"
              range-separator="——"
              start-placeholder="start date"
              end-placeholder="End Date"
              clearable
            />
          </template>
        </el-form-item>
      </el-form>
      <div class="panel-body">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column prop="orderId" fixed label="Order No" />
          <el-table-column prop="realOrderId" fixed label="Real Order" />
          <el-table-column prop="userPin" label="Account" />
          <el-table-column prop="orderAmount" label="Order Amount" />
          <el-table-column prop="orderState" label="Order Status">
            <template slot-scope="scope">
              <!-- 处理中 -->
              <span v-if="scope.row.orderState == 1">Processing</span>
              <!-- 等待付款 -->
              <span v-if="scope.row.orderState == 2">Awaiting Payment</span>
              <!-- 等待付款确认 -->
              <span v-if="scope.row.orderState == 3">Waiting For Payment Confirmation</span>
              <!-- 延迟付款确认 -->
              <span v-if="scope.row.orderState == 4">Late Payment Confirmation</span>
              <!-- 已支付 -->
              <span v-if="scope.row.orderState == 5">Payment Success</span>
              <!-- 已下单 -->
              <span v-if="scope.row.orderState == 6">Ordered</span>
              <!-- 暂停 -->
              <span v-if="scope.row.orderState == 11">Pending</span>
              <!-- 等待下传 -->
              <span v-if="scope.row.orderState == 12">Waiting For The Downlink</span>
              <!-- 等待打印 -->
              <span v-if="scope.row.orderState == 21">Awaiting Print</span>
              <!-- 等待出库 -->
              <span v-if="scope.row.orderState == 22">Waiting For Delivery</span>
              <!-- 等待打包 -->
              <span v-if="scope.row.orderState == 23">Waiting To Be Packed</span>
              <!-- 等待发货 -->
              <span v-if="scope.row.orderState == 24">Wait For Delivery</span>
              <!-- 等待收货 -->
              <span v-if="scope.row.orderState == 25">Awaiting Acceptence</span>
              <!-- 收款确认 -->
              <span v-if="scope.row.orderState == 26">Payment Confirmation</span>
              <!-- 自提途中 -->
              <span v-if="scope.row.orderState == 27">On The Way</span>
              <!-- 上门提货 -->
              <span v-if="scope.row.orderState == 28">Pick-up</span>
              <!-- 配送退货 -->
              <span v-if="scope.row.orderState == 29">Shipping Returns</span>
              <!-- 已完成 -->
              <span v-if="scope.row.orderState == 30">Compeleted</span>
              <!-- 自提退货	 -->
              <span v-if="scope.row.orderState == 31">Pick Up Return</span>
              <!-- 已取消 -->
              <span v-if="scope.row.orderState == 99">Cancelled</span>
              <!-- 锁定 -->
              <span v-if="scope.row.orderState == 100">Locking</span>
              <!-- 回到锁定状态 -->
              <span v-if="scope.row.orderState === -100">Locked</span>
              <!-- 店长最终审核 -->
              <span v-if="scope.row.orderState === 80">Store Manager Final Review</span>
              <!-- 等待客户回复 -->
              <span v-if="scope.row.orderState === 81">Waiting For Customer Reply</span>
              <!-- 未知 -->
              <span v-if="scope.row.orderState === -1">Unknown</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="contractId" label="Advances Rate" width="180" /> -->
          <el-table-column prop="bookTime" label="Book Time">
            <template slot-scope="scope">
              {{ formatDate(scope.row.bookTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="voucherImageAddress" label="VoucherImage">
            <template slot-scope="scope">
              <el-image 
                v-if="scope.row.voucherImageAddress"
                style="width: 100px; height: 100px"
                :src="scope.row.voucherImageAddress" 
                :preview-src-list="[scope.row.voucherImageAddress]"
              />
            </template>
          </el-table-column>
          <el-table-column prop="" label="Option">
            <template slot-scope="scope">
              <el-button type="text" @click="handleOpenDialog(scope.row)">upload</el-button>
              <!-- <el-button type="text" @click="handleOpenDialog(scope.row)">view</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="orderPage">
          <el-pagination
            style="float: right"
            :current-page="pages.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pages.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- 上传图片对话框 -->
    <otUploadDialog 
      :order-id="currentOrderId"
      :dialog-form-visible="otDialogVisible"
      @close="handleCloseDialog"
    />
    <!-- <otInfoDialog 
      :order-id="currentOrderId"
    /> -->
  </div>
</template>

<script>
import { otList, otSubmit } from '../../api/modifys/modify';
import otUploadDialog from './components/otUploadDialog.vue';
// import otInfoDialog from './components/otInfoDialog.vue';
export default {
  name: '',
  components: {
    otUploadDialog,
    // otInfoDialog
  },
  data() {
    return {
      thisApi: process.env.VUE_APP_BASE_API,
      form: {
        optionsValue: undefined
      },
      bookTime: [],
      tableData: [],
      pages: {
        page: 1,
        size: 10,
        total: 0
      },
      currentOrderId: '',
      otDialogVisible: false
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? '0' + MM : MM;
      let d = date.getDate();
      d = d < 10 ? '0' + d : d;
      let h = date.getHours();
      h = h < 10 ? '0' + h : h;
      let m = date.getMinutes();
      m = m < 10 ? '0' + m : m;
      let s = date.getSeconds();
      s = s < 10 ? '0' + s : s;
      return MM + '-' + d + '-' + y + ' ' + h + ':' + m + ':' + s;
    },
    getList() {
      const data = Object.assign(
        {
          pageNo: this.pages.page,
          pageSize: this.pages.size,
          orderState: 6
        },
        this.form
      );
      otList(data).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.pages.total  = res.data.totalCount;
        } else {
          this.tableData = [];
          this.pages.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    searchBtn() {
      if (this.bookTime != null) {
        this.form.startBookTime = this.bookTime[0];
        this.form.endBookTime = this.bookTime[1];
      }
      this.getList();
    },
    offReset() {
      this.form = {};
      this.getList();
    },
    selectHiden() {
      this.hide = !this.hide;
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    handleOpenDialog(row) {
      this.currentOrderId = row.orderId
      this.otDialogVisible = true
    },
    handleCloseDialog (){
      this.otDialogVisible = false
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.orderList {
  .modeify {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #fff;
    padding: 20px 0 0;
    box-sizing: border-box;
    .pageWrapper {
      /deep/.el-pagination {
        margin-top: 10px;
        float: right !important;
      }
      height: 45px;
    }
  }
  .fixed_form {
    padding: 0 20px;
    /deep/ .el-input__inner {
      width: 187px !important;
      height: 32px;
    }
    .el-button {
      font-size: 14px;
      ::v-deep .el-icon-search {
        font-size: 14px;
      }
    }
  }
  .el-form-item:last-of-type {
    /deep/ .el-input__inner {
      width: 340px !important;
    }
  }
  .lableHeight {
    /deep/ .el-form-item__label {
      line-height: 32px !important;
    }
  }
  .xiala {
    float: right;
    width: 118px;
    color: blue;
    margin: 8px 19% 0 0;
    i {
      margin-left: 10px;
    }
  }
  .View {
    margin-left: 10px;
  }
  .orderPage {
    height: 50px;
    .el-pagination {
      margin-top: 10px;
    }
  }
  .panel-body {
    padding: 0 16px;
  }
  /deep/.el-button {
    // padding: 0 12px !important;
    width: 100px;
    font-family: ArialMT;
    font-size: 14px;
  }

  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
  ::v-deep .el-button--text {
    color: #2262ff;
  }

  ::v-deep .el-link--primary {
    color: #2262ff;
  }

  ::v-deep .el-radio-group {
    .el-radio__label {
      color: #242526;
    }
    .is-checked {
      .el-radio__inner {
        background-color: #2262ff;
      }
      .el-radio__label {
        color: #242526;
      }
    }
  }

  ::v-deep .el-table {
    .cell {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  ::v-deep .el-pager {
    li {
      border: 0;
    }
  }
}
</style>
