<!--Check the discount rate-->
<template>
  <div class="panel CheckLst">
    <div class="serch_box">
      <el-form
        :model="form"
        class="user-search fixed_form"
        size="mini"
        :rules="rules"
      >
        <div>
          <el-form-item label="SKU:" size="mini" prop="skuIds">
            <template>
              <el-input
                v-model.trim="form.skuIds"
                placeholder="S10000 SKU at most, Seperated by comma；"
                size="mini"
                type="textarea"
                :rows="5"
                maxlength=""
                style="width:640px"
              />
            </template>
          </el-form-item>
          <el-form-item label="" size="mini">
            <el-button
              size="small"
              type=""
              style="float:left;margin:30px 10px"
              @click="submitSKU"
            >
              Submit
            </el-button>
          </el-form-item>
        </div>

        <div>
          <el-upload
            ref="fileList"
            class="upload-demo"
            :action="thisApi + '/system/rate/discountRateFile'"
            :on-success="successChange"
            style="float:left"
            :show-file-list="false"
            :limit="5"
          >
            <el-button size="" type="" icon="el-icon-document">
              Fill from Excel
            </el-button>
          </el-upload>
          <el-button
            size="small"
            type="text"
            icon="sl-icon-download"
            style="float:left;margin-left:30px"
            @click="downLoadsku"
          >
            Download Excel Template
          </el-button>
        </div>
      </el-form>
    </div>
    <div class="radio_less">
      <el-radio-group v-model.trim="resourceFlag">
        <el-radio :label="1">Discount Rate </el-radio>
        <el-radio :label="2"> Discount Amount</el-radio>
        <el-radio :label="3"> Sale Price</el-radio>
      </el-radio-group>
    </div>

    <div class="panel-body">
      <el-table
        :data="tableList"
        border
        fit
        style="width: 100%"
        size="mini"
        max-height="500"
        :header-cell-style="{ background: '#e8f1fe' }"
        @row-click="listClick"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799;">No more data</p>
        </template>
        <el-table-column
          label="SKU ID"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <el-button type="text">
              {{ scope.row.skuId }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          label="Mall Price"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.mallPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Moving Average Cost"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.Moving }}
          </template>
        </el-table-column>
        <el-table-column
          label="Purhase price"
          width=""
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.Purhase }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Discount Rate(100%)" width="220">
          <template slot-scope="scope">
            <el-input
              v-model.trim.number="scope.row.discountRate"
              size="small"
              oninput="value=value.replace(/[^0-9]/g,'')"
              placeholder=""
              style="100%"
              :disabled="resourceFlag != 1"
              @input="handleChangeMoney(scope.row, 1)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Discount Amount" width="220">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.discountAmount"
              size="small"
              type="number"
              style="100%"
              :disabled="resourceFlag != 2"
              @input="handleChangeMoney(scope.row, 2)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Sale Price" width="220">
          <template slot-scope="scope">
            <el-input
              v-model.trim.number="scope.row.sellingPrice"
              oninput="value=value.replace(/[^0-9]/g,'')"
              size="small"
              placeholder=""
              style="100%"
              :disabled="resourceFlag != 3"
              @input="handleChangeMoney(scope.row, 3)"
            />
          </template>
        </el-table-column>
        <el-table-column label="Sale Price" width="120">
          <template slot-scope="scope">
            {{ scope.row.discountSale }}
          </template>
        </el-table-column>
        <el-table-column label="Operate" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleDel(scope.$index)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
// 引入API接口
import {
  downloadTemplat,
  handBtn,
  getSKUjournal
} from '@/api/user/list';
import { checkLongOne } from '@/api/user/rules.js';
import { mapMutations } from 'vuex';
import NP from 'number-precision'
export default {
  // 引入局部刷新
  props: {
    icont: {
      type: String
    },
    dataListTable: {
      type: Array
    }
  },
  inject: ['reload'],
  data() {
    return {
      emptyImgSrc: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      ifupload: true,
      // 搜索条件
      form: {
        skuIds: ''
      },
      // 字典表
      UserFlagList: [{ value: '1', label: 'VSP' }],
      ValidityList: [
        { value: 0, label: 'Valid' },
        { value: 1, label: 'Invalid' }
      ],
      // 后端获取表格信息
      tableList: [],
      // 选中列表信息项
      multipleSelection: '',
      // 定义tabel切换初始位置
      outeObject: this.$route.query,
      unbind: false,
      rules: {
        skuIds: [
          {
            required: true,
            message: 'please enter the skuId',
            trigger: 'blur'
          },
          { validator: checkLongOne, length: 10000, trigger: 'blur' }
        ]
      },
      // 单选按钮 选中的标识
      resourceFlag: 1,
      thisApi: process.env.VUE_APP_BASE_API
    };
  },
  watch: {
    'form.skuIds': function(val) {
      this.$emit('childFn', { sku: this.form, list: this.tableList });
    },
    tableList: function() {
      this.$emit('childFn', { sku: this.form, list: this.tableList });
    },
    // 监听单选按钮 进行表格中的后续操作方法
    resourceFlag: function(val) {
      this.$emit('resourceFlag', val);
      // vuex 设置 单选按钮 值
      this.setIsResourcFlag(val);
      if (val == 1) {
        var list = [];
        for (let i = 0; i < this.tableList.length; i++) {
          let element = this.tableList[i];
          element.discountSale = element.mallPrice;
          list.push(element);
        }
        this.tableList = list;
      } else {
        var lists = [];
        for (let i = 0; i < this.tableList.length; i++) {
          let element = this.tableList[i];
          element.discountSale = element.mallPrice;
          lists.push(element);
        }
        this.tableList = lists;
      }
    },
    dataListTable(val) {
      if (val && val.length > 0) {
        let list = [];
        let skuIds = [];
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          if (element.discountRate) {
            element.discountSale = NP.divide(element.mallPrice, element.discountRate);
            // this.resourceFlag = 1;
          }
          if (element.discountAmount) {
            element.discountSale = NP.minus(element.mallPrice, element.discountAmount);
            element.sellingPrice = NP.minus(element.mallPrice, element.discountAmount);
            // this.resourceFlag = 2;
          }
          list.push(element);
          skuIds.push(element.skuId);
        }
        list.forEach((item,index) => {
          if (item.discountAmount) {
            item.sellingPrice = NP.minus(item.mallPrice, item.discountAmount);
            item.discountSale = NP.minus(item.mallPrice, item.discountAmount);
          } else if (item.discountRate) {
            item.sellingPrice = NP.divide(NP.times(item.mallPrice, item.discountRate), 100);
            item.discountSale = NP.divide(NP.times(item.mallPrice, item.discountRate), 100);
          }
        });
        this.tableList = list;
        this.form.skuIds = skuIds.toString();
      }
    }
  },
  mounted() {
    this.emptyImgSrc = '';
    if (Object.keys(this.$route.query) && Object.keys(this.$route.query).length > 0) {
      this.resourceFlag = this.$route.query.isResourceFlag;
    } else {
      this.resourceFlag = 1;
    }
  },

  methods: {
    ...mapMutations({
      setIsResourcFlag: 'setIsResourcFlag'
    }),
    handleDel(val) {
      let than = this;
      if (this.form.skuIds) {
        let arr = than.form.skuIds.split(',');
        this.form.skuIds.split(',').forEach((item,index) => {
          if (item == than.tableList[val].skuId) {
            arr.splice(index,1);
            than.form.skuIds = arr.toString();
          }
        });
      }
      this.tableList.splice(val, 1);
    },
    // 折扣之间的联动
    handleChangeMoney(row, oldIndex) {
      if (oldIndex === 1) { // 选择折扣率
        row.discountAmount = 0;
        row.discountSale = NP.divide(NP.times(+row.mallPrice, +row.discountRate), 100);
        row.sellingPrice = NP.divide(NP.times(+row.mallPrice, +row.discountRate), 100);
        if (!/^([0-9]{1,2}|100)$/.test(row.discountRate)) {
          this.$message({
            message: 'The discount rate should be between 0%-100%',
            type: 'error'
          });
          return
        }
      } else if (oldIndex === 2) { // 选择折扣金额
        row.discountRate = 0;
        row.discountSale = NP.minus(+row.mallPrice, +row.discountAmount);
        row.sellingPrice = NP.minus(+row.mallPrice, +row.discountAmount);
        if (row.discountSale < 0) {
          this.$message({
            message: 'Sale Price cannot be less than 0',
            type: 'error'
          });
          return
        }
      } else if (oldIndex === 3) { // 选择折扣金额
        row.discountRate = 0;
        row.discountAmount = NP.minus(+row.mallPrice, +row.sellingPrice);
        row.discountSale = row.sellingPrice;
        if (row.discountSale < 0) {
          this.$message({
            message: 'Sale Price cannot be less than 0',
            type: 'error'
          });
          return
        }
      }
    },
    // 获取页面数据
    getFirstData() {
      this.total = 1;
      getSKUjournal(
        Object.assign({
          page: this.listQuery.pageCode,
          size: this.listQuery.pageSize
        })
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          // 暂无数据
          this.tableList = [];
          this.listQuery.total = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 点击行时触发
    listClick(row) {
      this.multipleSelection = row;
    },
    successChange(file, fileList) {
      if (file.code == 200) {
        this.$message({
          message: 'successfully upload',
          type: 'success'
        });
        var list = [];
        var listBox = [];
        for (let i = 0; i < file.data.length; i++) {
          let element = file.data[i];
          if (element.sellingPrice) {
            element.discountSale = element.sellingPrice;
            // element.discountRate = (element.sellingPrice * 100/element.mallPrice);
            element.discountRate = 0;
            element.discountAmount = NP.minus(element.mallPrice, element.sellingPrice);
            this.resourceFlag = 3;
            this.setIsResourcFlag(3);
          } else if (element.discountRate) {
            element.discountSale = NP.divide(NP.times(element.mallPrice, element.discountRate), 100);
            element.sellingPrice = NP.divide(NP.times(element.mallPrice, element.discountRate), 100);
            element.discountAmount = 0;
            this.resourceFlag = 1;
            this.setIsResourcFlag(1);
          } else if (element.discountAmount) {
            element.discountRate = 0;
            element.discountSale = NP.minus(element.mallPrice, element.discountAmount);
            element.sellingPrice = NP.minus(element.mallPrice, element.discountAmount);
            this.resourceFlag = 2;
            this.setIsResourcFlag(2);
          }
          list.push(element.skuId);
          listBox.push(element);
        }
        this.form.skuIds = list.toString();
        this.tableList = listBox;
      }
    },
    downLoadsku() {
      downloadTemplat().then(response => {
        this.isExcel('xlsx', 'Template', response);
      });
    },
    isExcel(type, name, data) {
      const link = document.createElement('a');
      const blob = new Blob([data]);
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${name}.` + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    submitSKU() {
      if (this.form.skuIds == '') {
        this.$message({
          message: 'please enter skuId',
          type: 'error'
        });
      } else {
        handBtn(this.form).then(res => {
          if (res.code == 200) {
            if (res.data.msg == 'All SKU are qualified and can be added.') {
              this.$message({
                message: 'Operation succeeded',
                type: 'success'
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
            }
            if (res.data.data && res.data.data.length > 0) {
              let listArr = [];
              res.data.data.forEach((item,index) => {
                listArr.push({
                  approvedState: 0,
                  contractNo: '',
                  costPrice: item.costPrice,
                  created: item.created,
                  creator: item.creator,
                  discountAmount: null,
                  discountRate: item.discountRate,
                  discountSale: null,
                  discountType: 0,
                  enterpriseName: '',
                  fileList: null,
                  filePath: null,
                  id: item.id,
                  mallPrice: item.mallPrice,
                  modified: item.modified,
                  modifier: item.modifier,
                  recordId: 100000780,
                  skuId: item.skuId,
                  skuName: item.skuName,
                  state: item.state,
                  thrCateId: item.thrCateId,
                  thrCateName: item.thrCateName
                })
              })
              this.tableList = listArr;
            }  else {
              this.tableList = [];
            }
          } else {
            this.$message({
              message:
                'Some SKU cannot be identified and have been cleared. Okay',
              type: 'error'
            });
          }
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.CheckLst {
  margin-bottom: 40px;
  margin-top: 10px;
  .el-input {
    width: 200px;
    margin-right: 30px;
  }
  .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.serch_box {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 16px solid #eff0f4;
}
.fixed_form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  div:nth-of-type(1) {
    display: flex !important;
    /deep/.el-form-item__label {
      text-align: left !important;
    }
  }
  div:nth-of-type(2) {
    display: flex;
    justify-content: center;
  }
}

/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
}
.radio_less {
  padding: 20px;
  box-sizing: border-box;
}
</style>
