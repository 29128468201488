<!--brandWall-->
<template>
  <div class="panel bannerWall">
    <div class="title">Brand Street Settings</div>
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form">
        <el-form-item label="Brand Name:" size="">
          <el-input
            v-model.trim="form.brandName"
            placeholder="Pleas Enter"
            size=""
          />
        </el-form-item>
        <el-form-item label="Enterprise Name:" size="mini">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No:" size="">
          <template>
            <el-input v-model.trim="form.contractNo" placeholder="Pleas Enter">
              <el-button
                slot="append"
                style="width:30px;height:32px;line-height: 32px;text-align:center;"
                @click="showSku"
              >
                <i class="el-icon-search" style="margin-top:16px" />
              </el-button>
            </el-input>
          </template>
        </el-form-item>
        <el-button type="" @click="offBtn">Reset</el-button>
        <el-button type="primary" icon="el-icon-search" @click="searchBtn">
          Search
        </el-button>
      </el-form>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin-top: 32px"
        @click="addBtn"
      >
        Add
      </el-button>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        :header-cell-style="{ background: '#e8f1fe' }"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column label="No." width="50" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>
              {{ scope.row.priority || '--' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="Brand Name"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.brandName || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Contract No"
          min-width="210"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractNo || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Image" min-width="180">
          <template slot-scope="scope">
            <img
              :src="scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl"
              width="130px"
              style="cursor: pointer"
              @click="handlePreview(scope.row.imgUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.imgUrl : scope.row.imgUrl)"
            >
          </template>
        </el-table-column>
        <el-table-column
          label="Target URL"
          width="300"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.url || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Status"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <i
              :ref="scope.$index"
              :class="
                scope.row.state == 0 ? 'el-icon-success' : 'el-icon-error'
              "
              :style="scope.row.state == 0 ? 'color:#40C740' : 'color:#FA5050'"
              style="margin-right: 5px"
            />
            <span>{{ scope.row.state == 0 ? 'Valid' : 'Invalid' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operate" min-width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              
              type="text"
              size="small"
              @click="handleUpdate(scope.row)"
            >
              Edit
            </el-button>
            <el-button
              
              type="text"
              size="small"
              @click="handleDel(scope.row)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      width="800px"
      @closed="dialogFormVisibleFn"
    >
      <el-form
        ref="postForm"
        class="dialog-form"
        :model="postForm"
        :rules="rules"
        label-width="180px"
        :inline="true"
      >
        <el-form-item label="Sequence Number" prop="priority">
          <!-- <el-input-number
            v-model.trim="postForm.priority"
            :min="1"
            :max="5"
            aria-placeholder="Please enter the Squence number"
          /> -->
          <el-select
            v-model.trim="postForm.priority"
            size="small"
            placeholder="Please select"
          >
            <el-option
              v-for="item in optionsNumber"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dialogStatus == 'create'" label="Enterprise Name">
          <template>
            <el-select v-model="enterpriseNameCodeAdd" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseAddFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No">
          <el-input
            v-model.trim="postForm.contractNo"
            type
            placeholder="Please enter the Contract No"
            maxlength="50"
            :disabled="dialogStatus == 'update'"
          />
        </el-form-item>
        <el-form-item label="Brand Name" prop="brandName">
          <el-input
            v-model.trim="postForm.brandName"
            type
            placeholder="Please enter the brand name"
            maxlength="50"
            disabled
          />
        </el-form-item>

        <el-form-item label="Image" prop="imgUrl">
          <el-input
            v-model.trim="postForm.imgUrl"
            type
            placeholder="Please enter the image URL"
            maxlength="100"
            style="float: left"
            disabled
          />
          <el-upload
            class="upload-demo"
            :action="thisApi + '/system/ware/file/uploadImg'"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            list-type="picture"
            :on-success="successChange"
            :show-file-list="false"
            style="float: left"
          >
            <el-button size="small" type="" icon="sl-icon-download">
              Upload
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="Target URL" prop="url">
          <el-input
            v-model.trim="postForm.url"
            type
            placeholder="Please enter the Target url"
            maxlength="150"
          />
        </el-form-item>
        <el-form-item label="Status" prop="state">
          <template>
            <el-radio-group v-model.trim="postForm.state">
              <el-radio :label="0" style="margin-right: 16px">Valid</el-radio>
              <el-radio :label="1">Invalid</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" style="margin-bottom: 20px">
        <el-button
          type=""
          @click="dialogFormVisibleFn"
        >
          Cancel
        </el-button>
        <el-button
          v-if="dialogStatus == 'create'"
          type="primary"
          @click="createData('postForm')"
        >
          Confirm
        </el-button>
        <el-button
          v-else-if="dialogStatus == 'update'"
          
          type="primary"
          @click="updateData('postForm')"
        >
          Confirm
        </el-button>
      </div>
      <div class="serch_box" style="border-top: 16px solid #eff0f4">
        <el-form :inline="true" class="user-search fixed_form">
          <el-form-item label="brand id:">
            <template>
              <el-input
                v-model.trim.number="formList.brandId"
                placeholder="Pleas enter the brand id"
                oninput="value=value.replace(/[^0-9]/g,'')"
                maxlength="9"
              />
            </template>
          </el-form-item>
          <el-form-item label="Brand Name:">
            <template>
              <el-input
                v-model.trim="formList.brandName"
                placeholder="Pleas enter the brand name"
              />
            </template>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            style="margin-top: 20px"
            @click="searchBtnL"
          >
            Search
          </el-button>
        </el-form>
      </div>
      <div class="panel-body">
        <el-table
          ref="tb"
          :data="dialogList"
          border
          fit
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
          @selection-change="handleSelectionChange"
          @select-all="onSelectAll"
        >
          <el-table-column type="selection" width="40px" />
          <el-table-column label="ID" width="150" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>
                {{ scope.row.brandId || '--' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Name"
            width="130"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.name || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="English Name"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.enName || '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Image" min-width="180">
            <template slot-scope="scope">
              <img :src="scope.row.logoUrl.indexOf('http') == -1 ? imgHeaderInit + scope.row.logoUrl : scope.row.logoUrl" width="100%">
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            width="150"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.status == 2 ? 'valid' : 'invalid' }}</span>
            </template>
          </el-table-column>
          <!-- 修改无数据时 提示文字 -->
          <div
            slot="empty"
            style="
              height: 200px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <no-data class="no-data-class" />
          </div>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="listQueryCen.pageCode"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="listQueryCen.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listQueryCen.total"
            @size-change="handleSizeCen"
            @current-change="handleCurrentCen"
          />
        </div>
      </div>
    </el-dialog>
    <!-- 缩略图 -->
    <el-dialog :visible.sync="dialogVisible" width="1000px">
      <img
        style="display: block; margin: 0 auto 40px; max-width: 100%"
        :src="dialogImageUrl"
        alt=""
      >
    </el-dialog>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import {
  brandList,
  brandDetail,
  addBrand,
  updataBrand,
  deleteBrand,
  getCenBrand,
  downImg
} from '@/api/user/list';
import { ifhttp } from '@/api/user/rules.js';
import Delotal from '../contractManage/conttemplat.vue';
import {image_base_url} from '@/common/config/index.js'
import { getEnterpriseName } from '@/api/enterprise/enterprise';
// 暂无数据组件
import noData from '@/components/noData';
const defaultForm = {
  contractNo: '',
  priority: '',
  brandName: '',
  url: '',
  imgUrl: '',
  state: 0
};
export default {
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Delotal,
    noData
  },
  data() {
    var valiIcon = (rule, value, callback) => {
      if(!this.postForm.imgUrl) callback();
      // 图片验证
      var height = 500;
      var width = 1000;
      let image = new Image();
      image.src = this.imgHeaderInit + this.postForm.imgUrl;
      // 判断是否有缓存
      if(image.complete){
        if (image.height !== height || image.width !== width) {
          callback(new Error('Please upload 1000 × 500 pictures'));
        } else {
          callback();
        }
      }else{
        // 加载完成执行
        image.onload = function(){
          if (image.height !== height || image.width !== width) {
            callback(new Error('Please upload 1000 × 500 pictures'));
          } else {
            callback();
          }
        };
      }
    };
    return {
      emptyImgSrc: '',
      imgHeaderInit: image_base_url,
      postForm: Object.assign({}, defaultForm),
      tableRadio: '',
      radios: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 1
      },
      listQueryCen: {
        pageSize: 10,
        pageCode: 1,
        total: 1
      },
      // 配置弹窗信息
      textMap: {
        update: 'Update Brand Street',
        create: 'Create Brand Street'
      },
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImageUrl: '',
      dialogStatus: 'update',
      // 搜索条件
      form: {
        contractNo: '',
        brandName: ''
      },
      formList: {
        brandId: '',
        brandName: ''
      },
      fileList: [],
      // 后端获取表格信息
      tableList: [],
      dialogList: [],
      // 选中列表信息项
      multipleSelection: [],
      // 非空验证
      rules: {
        priority: [
          {
            required: true,
            message: 'Please enter the Sequence number',
            trigger: 'blur'
          }
        ],
        brandName: [
          {
            required: true,
            message: 'Please enter the brand Name',
            trigger: 'blur'
          }
        ],
        contractNo: [
          {
            required: true,
            message: 'Please enter the Contract No',
            trigger: 'blur'
          }
        ],
        imgUrl: [
          {
            // required: true,
            message: 'Please upload imgUrl.',
            trigger: 'blur'
          },
          { validator: valiIcon, trigger: 'change' } // 图片验证
        ],
        state: [
          {
            required: true,
            message: 'Please Choose Status',
            trigger: 'change'
          }
        ],
        url: [
          {
            required: true,
            message: 'Please enter the Target url',
            trigger: 'change'
          },
          { validator: ifhttp, length: 6, trigger: 'blur' }
        ]
      },
      loading: true,
      loadingTwo: true,
      thisApi: process.env.VUE_APP_BASE_API,
      optionsNumber: [
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 10, label: 10 }
      ],
      dialogSKU: false,
      multipleSelections: '',
      EnterpriseList: [],
      enterpriseNameCode: '',
      enterpriseNameCodeAdd: ''
    };
  },
  watch: {
    dialogFormVisible: async function() {
      await this.$nextTick();
      this.$refs['postForm'].resetFields();
      this.form = {
        contractNo: '',
        brandName: ''
      };
      this.formList = {
        brandId: '',
        brandName: ''
      };
    }
  },
  created() {
    // 初始化表数据
    this.getFirstData();
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  methods: {
    // 添加页面 切换企业名称，回显合同号
    changeEnterpriseAddFn(val) {
      if (this.dialogStatus == 'create') {
        this.postForm.contractNo = val;
      }
    },
    // 弹窗取消
    dialogFormVisibleFn() {
      this.dialogFormVisible = false;
      this.enterpriseNameCodeAdd = '';
    },
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      this.multipleSelections = val;
    },
    updateDatas() {
      console.log(this.multipleSelections);

      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    handleSelectionChange(val) {
      if (val.length > 1) {
        this.$refs.tb.clearSelection();
        this.$refs.tb.toggleRowSelection(val.pop());
      } else {
        this.postForm.brandId = val[0].brandId;
        this.postForm.brandName = val[0].name;
      }
    },
    onSelectAll() {
      this.$refs.tb.clearSelection();
    },
    // 获取页面数据
    getFirstData() {
      brandList(
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total = res.data.totalCount;
        } else {
          this.tableList = [];
          this.listQuery.total = 0
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.postForm = Object.assign({}, defaultForm);
      this.radios = '';
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.getCenBrand();
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    handleSizeCen(val) {
      //console.log(val);
      this.listQueryCen.pageSize = val;
      this.getCenBrand();
    },
    handleCurrentCen(val) {
      //console.log(val);
      this.listQueryCen.pageCode = val;
      this.getCenBrand();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    searchBtnL() {
      this.listQueryCen.pageCode = 1;
      this.getCenBrand();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    // 列表-修改按钮
    handleUpdate(row) {
      console.log(row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.brandDetail(row);
      this.formList.brandId = row.brandId;
      this.getCenBrand();
    },
    brandDetail(row) {
      brandDetail(row).then(res => {
        this.postForm = res.data;
      });
    },
    getCenBrand() {
      getCenBrand(
        Object.assign(
          {
            pageNo: this.listQueryCen.pageCode,
            pageSize: this.listQueryCen.pageSize
          },
          this.formList
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.dialogList = res.data.dataList;
          this.listQueryCen.total = res.data.totalCount;
        } else {
          this.dialogList = [];
          this.listQueryCen.total = 0
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 列表-删除按钮
    handleDel(row) {
      this.$confirm('Are you sure to remove this banner?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      })
        .then(() => {
          deleteBrand(row).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully delete',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
              
            }
          });
        })
        .catch(function() {});
    },
    // 弹窗-新增确定
    createData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          addBrand(this.postForm).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully upload',
                type: 'success'
              });
              this.getFirstData();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
              
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 弹窗-修改确定
    updateData() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          updataBrand(this.postForm).then(res => {
            if (res.code == 200) {
              this.$message({
                message: 'successfully upload',
                type: 'success'
              });
              this.dialogFormVisible = false;
              this.getFirstData();
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
             
            }
          });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    handleChange() {},
    // 上传成功
    successChange(file, fileList) {
      this.postForm.imgUrl = file.msg;
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    handlePreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    clickChange(item) {
      //console.log(item);
      this.tableRadio = item;
    }
  }
};
</script>
<style lang="less" scoped>
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bannerWall {
  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
  .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.title {
  height: 66px;
  border-bottom: 1px solid rgba(183, 188, 201, 0.3);
  padding: 24px 16px;
  font-size: 16px;
  font-weight: Bold;
  line-height: 17px;
}
.panel-body {
  padding: 0 16px;
}

/deep/.el-input-number__decrease.is-disabled,
.el-input-number__increase.is-disabled {
  display: none;
}
/deep/.el-input-number__increase {
  display: none;
}
/deep/.el-input-number__decrease {
  display: none;
}
/* ::v-deep .el-input-number {
  .el-input__inner {
    width: 200px;
    height: 32px;
    padding: 0 15px;
    text-align: left;
  }
} */

.serch_box {
  width: 100%;
  background: #fff;
  padding: 24px 16px 18px;
  ::v-deep .el-input {
    width: 200px;
    margin-right: 40px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #2262ff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.fixed_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    display: flex;
    //flex-direction: column;
    margin-bottom: 0;
    /deep/.el-form-item__label {
      text-align: left;
    }
  }
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

.dialog-form {
  ::v-deep .el-form-item {
    display: flex;
    .el-form-item__label {
      font-size: 14px;
      color: #242526;
      font-weight: 500;
    }
    .el-form-item__content {
      width: 374px;
    }
    .el-input {
      width: 350px;
      margin-right: 24px;
    }
    .el-input__inner {
      width: 350px;
      height: 32px;
      padding: 0 15px;
      text-align: left;
    }
  }
}
::v-deep .el-dialog__body {
  padding: 0 16px 0px;
}
</style>
